import React from "react";
import aboutPic from "../media/gallery/lakes/3.jpg";
import { About, Description, Image, Hide } from "../styles";
//Framer Motion
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import Wave from "./Wave";
import styled from "styled-components";

const AboutSection = () => {
  return (
    <About>
      <Description>
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>
              <span id="name">Karim Bounekhla</span>
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              Software Engineer | Photographer
            </motion.h2>
          </Hide>
        </motion.div>
        <ButtonsRow>
          <Link
            onClick={() => window.open("https://github.com/karimbounekhla")}
          >
            <motion.button id="home-button" variants={fade}>
              GitHub
            </motion.button>
          </Link>
          <Link
            onClick={() =>
              window.open("https://www.linkedin.com/in/karimbounekhla/")
            }
          >
            <motion.button id="home-button" variants={fade}>
              LinkedIn
            </motion.button>
          </Link>
          <Link
            onClick={() =>
              window.open("mailto:karim.bounekhla@mail.utoronto.ca")
            }
          >
            <motion.button id="home-button" variants={fade}>
              Contact
            </motion.button>
          </Link>
        </ButtonsRow>
      </Description>
      <Image>
        <motion.img variants={photoAnim} src={aboutPic} alt="previewpic" />
      </Image>
      <Wave />
    </About>
  );
};

const ButtonsRow = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;

  button {
    margin: 15px 10px 0 0;
  }

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

export default AboutSection;
